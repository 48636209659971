
import { checkState } from "./state";
import router from "@/router";

// import Storage from '@/utils/localStorage'
function updateUser(state: checkState) {
  return (user: any) => {
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  };
}
function updateUserList(state: checkState) {
  return (userList: any) => {
    // 通过id查找该应用在userList 的位置
    const indexId = state.userList.findIndex(
      (item: any) => item.id === userList.id
    );
    // 不存在则添加 存在则替换
    if (indexId === -1) {
      state.userList.push(userList);
    } else {
      state.userList.splice(indexId, 1, userList);
    }
    localStorage.setItem("userList", JSON.stringify(state.userList));
  };
}
function updateUserListDefaultModel(state: checkState) {
  return (appCode: string, defaultModel: any) => {
    const indexId = state.userList.findIndex(
      (item: any) => item.id === appCode
    );
    state.userList[indexId].default_model = defaultModel
    localStorage.setItem("userList", JSON.stringify(state.userList));
  };
}
function updataUserListTC(state: checkState) {
  return (appCode: string, name: string, isTrue: boolean) => {
    const index = state.userList.findIndex(
      (item: any) => item.id === appCode
    );
    switch (name) {
      case 'thumb':
        state.userList[index].thumb = !state.userList[index].thumb
        // console.log(state.nowSelect.thumb)
        // console.log(state.nowSelect.thumb)
        // state.nowSelect.thumb = !state.nowSelect.thumb
        if (isTrue) {
          state.userList[index].thumbCount -= 1
          // if(state.nowSelect.thumbCount)    state.nowSelect.thumbCount -= 1
        } else {
          state.userList[index].thumbCount += 1
          // if(state.nowSelect.thumbCount)    state.nowSelect.thumbCount += 1
        }
        break;
      case 'collect':
        state.userList[index].collect = !state.userList[index].collect
        // state.nowSelect.collect = !state.nowSelect.collect
        if (isTrue) {
          state.userList[index].collectCount -= 1
          // if(state.nowSelect.collectCount)    state.nowSelect.collectCount -= 1
        } else {
          state.userList[index].collectCount += 1
          // if(state.nowSelect.collectCount)    state.nowSelect.collectCount += 1
        }
        break;
    }
    localStorage.setItem("nowSelect", JSON.stringify(state.nowSelect));
    localStorage.setItem("userList", JSON.stringify(state.userList));
  }
}
function repeatUserList(state: checkState) {
  return (userList: any) => {
    state.userList = userList;
  };
}

function repeatNowSelect(state: checkState) {
  return (nowSelect: any) => {
    state.nowSelect = nowSelect;
    localStorage.setItem("nowSelect", JSON.stringify(state.nowSelect));
  };
}

function repeatChatData(state: checkState) {
  return (chatData: any) => {
    state.chatData = chatData;
  };
}
function checkPhone(state: checkState) {
  return (isPhone: any) => {
    state.isPhone = isPhone;
  };
}
function updateChatData(state: checkState) {
  return (
    id: any,
    data: any,
    isFirst = false,
    replaceLastData = false
  ) => {
    const indexFind = state.chatData.findIndex((item: any) => {
      return item.roleId === id;
    });
    if (indexFind === -1) {
      state.chatData.push({ roleId: id, data: [data] });
    } else {
      if (replaceLastData) {
        state.chatData[indexFind].data[
          state.chatData[indexFind].data.length - 1
        ] = data;
      } else {
        if (!isFirst) state.chatData[indexFind].data.push(data);
      }
    }
    localStorage.setItem("chatData", JSON.stringify(state.chatData));
  };
}

function setToken(state: checkState) {
  return (token: any) => {
    state.token = token;
    localStorage.setItem("token", state.token);
  };
}

function clearToken(state: checkState) {
  return () => {
    state.token = "";
    localStorage.removeItem("token");
  };
}
function clearState(state: checkState) {
  return () => {
    state.user = {};
    state.chatData = [];
    state.userList = [];
    state.nowSelect = {};
    state.token = "";
  };
}
function deleteChatRole(state: checkState) {
  return (chatRole: any, index: number) => {
    state.userList.splice(index, 1);
    localStorage.setItem("userList", JSON.stringify(state.userList));
    state.chatData.splice(index, 1);
    localStorage.setItem("chatData", JSON.stringify(state.chatData));

    if (chatRole.id !== state.nowSelect.id) return;
    if (state.userList.length > 0) {
      if (index > 0) {
        state.nowSelect = state.userList[index - 1];
      } else {
        state.nowSelect = state.userList[0];
      }
      localStorage.setItem("nowSelect", JSON.stringify(state.nowSelect));
      router.push({
        name: "app",
        params: { code: state.nowSelect.code },
      });
    } else {
      state.nowSelect = {};
      localStorage.setItem("nowSelect", JSON.stringify(state.nowSelect));
      router.push({
        name: "home",
      });
    }
  };
}

function updataBalance(state: checkState) {
  return (balance: any) => {
    state.balance = balance;
  };
}
function upDataIsWexin(state: checkState) {
  return (isWxin: any) => {
    state.weixin = isWxin;
  };
}
function upDateSearchTime(state: checkState) {
  return (searchTime: number) => {
    state.searchTime = searchTime;
  };
}
function upDateSearchInput(state: checkState) {
  return (searchTimeInput: string) => {
    state.searchTimeInput = searchTimeInput;
  };
}
function upDateScoreTime(state: checkState) {
  return (scoreTime: number) => {
    state.scoreTime = scoreTime;
  };
}

function updataAllModel(state: checkState) {
  return (modelList: any) => {
    state.allModel = modelList
  }
}
function repeatAllPlugin(state:checkState ){
  return (allPlugin: any) => {
    state.allPluginUser = allPlugin
  }
}
function updataAllPlugin(state: checkState) {
  return (allPlugin: any) => {
    const pluginList = state.allPluginUser
    allPlugin.forEach((item2: any) => {
      // 检查第一个数组中是否存在相同的code
      const index1 = pluginList.findIndex((item1: any) => item1.code === item2.code);
      if (index1 !== -1) {
        // 如果存在相同的code，则使用第一个数组中的值
        pluginList[index1] = item2;
      } else {
        // 如果第一个数组中不存在相同的code，则将元素添加到第一个数组中
        pluginList.push(item2);
      }
    });
    // 遍历第一个数组
    pluginList.forEach((item1: any, index1: number) => {
      // 检查第二个数组中是否存在相同的code
      const index2 = allPlugin.findIndex((item2: any) => item1.code === item2.code);
      if (index2 === -1) {
        // 如果第二个数组中不存在相同的code，则从第一个数组中删除该元素
        pluginList.splice(index1, 1);
      }
    });
    state.allPluginUser = pluginList
    localStorage.setItem("allPluginUser", JSON.stringify(state.allPluginUser));
  }
}

function replacePluginEnabled(state: checkState){
  return (allPlugin: any) => {
    allPlugin.forEach((item2: any) => {
      const index1 = state.allPluginUser.findIndex((item1: any) => item1.code === item2.code)
      if(index1 > -1 && !state.allPluginUser[index1].enabled){
        state.allPluginUser[index1].enabled = item2.enabled
      }
    })
    localStorage.setItem("allPluginUser", JSON.stringify(state.allPluginUser));
  }
}

function updateEnabled(state: checkState){
  return (e: string, plugin: any) => {
    const index1 = state.allPluginUser.findIndex((item1: any) => item1.code === plugin.code)
    if(index1 > -1) {
      state.allPluginUser[index1].enabled = e
    }
    localStorage.setItem("allPluginUser", JSON.stringify(state.allPluginUser));
  }
}
/**
 * 创建Action
 * @param state
 */
export function createAction(state: checkState) {
  return {
    updateUser: updateUser(state),
    updateUserList: updateUserList(state),
    updateUserListDefaultModel: updateUserListDefaultModel(state),
    updateChatData: updateChatData(state),
    checkPhone: checkPhone(state),
    repeatUserList: repeatUserList(state),
    repeatNowSelect: repeatNowSelect(state),
    repeatChatData: repeatChatData(state),
    setToken: setToken(state),
    clearState: clearState(state),
    updataBalance: updataBalance(state),
    clearToken: clearToken(state),
    deleteChatRole: deleteChatRole(state),
    upDataIsWexin: upDataIsWexin(state),
    upDateSearchTime: upDateSearchTime(state),
    upDateSearchInput: upDateSearchInput(state),
    upDateScoreTime: upDateScoreTime(state),
    updataUserListTC: updataUserListTC(state),
    updataAllModel: updataAllModel(state),
    updataAllPlugin: updataAllPlugin(state),
    repeatAllPlugin: repeatAllPlugin(state),
    replacePluginEnabled: replacePluginEnabled(state),
    updateEnabled: updateEnabled(state)
  };
}
