import service from "../utils/request";

interface UserInfoParam {
  userID: string;
  userName: string;
}
export function apiGetUserInfo(param: UserInfoParam) {
  return service({
    url: "/api/chat/web/role/all",
    method: "get",
    data: param,
  });
}
interface CharActers {
  message?: string;
}
export function apiCharActers(param: CharActers) {
  // return service.get('/api/data', { responseType: 'text/event-stream' })
  return service({
    method: "get",
    url: "/api/chat/characters",
    data: param,
  });
}

interface RoleGroupList {
  data?: string;
}
export function roleGroupList(param: RoleGroupList) {
  // return service.get('/api/data', { responseType: 'text/event-stream' })
  return service({
    method: "get",
    url: "/api/chat/web/role/group/list",
    data: param,
  });
}
export function authLogin() {
  return service({
    method: "post",
    url: "/api/auth/login",
  });
}

export function wxLogin() {
  return service({
    method: "get",
    url: "/api/wx/login/getWechatQtCode",
  });
}
interface WxLogin {
  key?: string;
}

// export function getWxLogin(param: WxLogin) {
//   return service({
//     method: "get",
//     url: "/api/wx/login/login/check/test",
//     params: param,
//   });
// }
interface RoleList {
  groupId?: string | number;
  pageNo?: number;
  pageSize?: number;
  key?: string;
  actionType?: string
}
export function roleList(param: RoleList) {
  return service({
    method: "get",
    url: "/api/chat/web/role/list",
    params: param,
  });
}

interface ChatRequest {
  prompt?: string;
  appCode?: string;
}
export function completion(data: ChatRequest) {
  return service({
    method: "post",
    url: "/api/chat/web/completion",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
interface GetWeChatPublicAccountImageCheck {
  share?: string;
}
export function getWeChatPublicAccountImage(
  param?: GetWeChatPublicAccountImageCheck
) {
  return service({
    method: "get",
    url: "/api/wx/public/login/getWeChatPublicAccountImageBase64",
    params: param,
  });
}

export function loginCheckState(param: WxLogin) {
  return service({
    method: "get",
    url: "/api/wx/public/login/check/state",
    params: param,
  });
}

export function bindCheckState(param: WxLogin) {
  return service({
    method: "get",
    url: "/api/wx/public/login/check/bind/state",
    params: param,
  });
}
interface RoleAddCheck {
  description?: string;
  groupId?: number | null;
  headImageUrl?: string;
  introduction?: string;
  name?: string;
  prompt?: string;
  type?: string;
  isPublic?: string;
  fileName?: string;
}
function roleAdd(param: RoleAddCheck) {
  return service({
    method: "post",
    url: "/api/chat/web/app/add",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

interface MyRoleCheck {
  pageNo?: number;
  pageSize?: number;
}
function myRole(param: MyRoleCheck) {
  return service({
    method: "get",
    url: "/api/chat/web/app/my",
    params: param,
  });
}

interface RoleDetailCheck {
  code?: string;
}
const roleDetail = async (param: RoleDetailCheck) => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/role/detail",
    params: param,
  });
};

const roleHeighDetail = async (param: RoleDetailCheck) => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/role/senior/detail",
    params: param,
  });
};
interface ApiKeyCheck {
  name?: string;
}
const apiKey = async (param?: ApiKeyCheck) => {
  // return await service({
  //   method: "get",
  //   url: "/api/chat/web/app/user/create/api/key",
  // });
  return service({
    method: "post",
    url: "/api/chat/web/app/user/create/api/key",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const apiKeyList = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/user/create/api/key",
  });
};

interface ChangeRoleSwitchCheck {
  code?: string;
  isDisplayOnAppSquare?: string;
}
const changeRoleSwitch = async (param: ChangeRoleSwitchCheck) => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/role/switch",
    params: param,
  });
};

// 修改用户资料
interface UserUpdateCheck {
  nickName?: string;
  headImageUrl?: string;
}
const userUpdate = async (param: UserUpdateCheck) => {
  return await service({
    method: "post",
    url: "/api/chat/web/app/user/update",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    data: param,
  });
};

interface UserEditUpdateCheck {
  code?: string;
  description?: string;
  displayOnAppSquare?: boolean;
  groupId?: number;
  headImageUrl?: string;
  introduction?: string;
  name?: string;
  prompt?: string;
  textUrl?: string;
  type?: string;
  temperature?: number;
  similarity?: string;
  reply_strategy?: string;
}
const userEditUpdate = async (param: UserEditUpdateCheck) => {
  return await service({
    method: "post",
    url: "/api/chat/web/app/update",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getProduct = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/user/getProduct",
  });
};

interface rechargeOrderCheck {
  code?: string;
  goodId?: string;
}
const rechargeOrder = async (param: rechargeOrderCheck) => {
  return await service({
    method: "post",
    url: "/api/chat/web/app/user/createOrder",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getBalance = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/user/get/balance",
  });
};

interface GetUserOrderCheck {
  pageNo?: number;
  pageSize?: number;
}
const getUserOrder = async (param: GetUserOrderCheck) => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/user/getOrder",
    params: param,
  });
};
interface GetRecordDetailCheck {
  orderNo?: string;
}
const getRecordDetail = async (param: GetRecordDetailCheck) => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/user/order/detail",
    params: param,
  });
};

interface queryFormCheck {
  pageNo?: number;
  pageSize?: number;
  appCode?: string;
  endTime?: string;
  model?: string;
  source?: string;
  startTime?: string;
  type?: string
}
const getInvokeRecord = async (param: queryFormCheck) => {
  return await service({
    method: "get",
    url: "/api/chat/web/getInvokeRecord",
    params: param,
  });
};
const getInvokeRecordEnum = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/getInvokeRecordQueryParamEnum",
  });
};
interface DeleteFileCheck {
  appCode?: string;
  fileId?: string;
}
const deleteFile = async (param: DeleteFileCheck) => {
  return await service({
    method: "post",
    url: "/api/web/data/delete",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface AddFileCheck {
  code?: string;
  url?: string;
  originFileName?: string;
}
const addFile = async (param: AddFileCheck) => {
  return await service({
    method: "post",
    url: "/api/web/embedding",
    data: JSON.stringify(param),
    timeout: 5 * 6 * 10000,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface appCodeCheck {
  appCode?: string;
}

const deleteRole = async (param: appCodeCheck) => {
  return await service({
    method: "delete",
    url: "/api/chat/web/app/delete/role",
    params: param,
  });
};

// const weXinLogin = async () => {
//   return await service({
//     method: "get",
//     url: "/api/wx/OAuth/login/getAuthUrl",
//   });
// };
interface getCodeCheck {
  code?: string;
  share?: string;
}
const applyTokenByCode = async (param: getCodeCheck) => {
  return await service({
    method: "get",
    url: "/api/wx/OAuth/login/apply/token",
    params: param,
  });
};

const uploadLinkFile = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/upload",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: param,
  });
};

const deleteContext = async (param: appCodeCheck) => {
  return await service({
    method: "post",
    url: "/api/web/chat/context/delete",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};

interface deleteAppKeyCheck {
  id?: string;
}
const deleteAppKey = async (param: deleteAppKeyCheck) => {
  return await service({
    method: "delete",
    url: "/api/chat/web/app/user/delete/api/key",
    params: param,
  });
};

interface fileListCheck {
  appCode?: string;
  fileId?: string;
  pageNo?: number;
  pageSize?: number;
}
const queryfileList = async (param: fileListCheck) => {
  return await service({
    method: "post",
    url: "/api/web/data/list",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};
interface editFileListCheck {
  id?: string;
  appCode?: string;
  fileId?: string;
  text?: string;
  question?: string;
  string?: string;
  onlySearchQuestion?: any
}

const editfileList = async (param: editFileListCheck) => {
  return await service({
    method: "post",
    url: "/api/web/data/edit",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};

interface addFileListCheck {
  appCode?: string;
  fileId?: string;
  text?: string;
  question?: string;
  string?: string;
  onlySearchQuestion?: any
}

const addFileList = async (param: addFileListCheck) => {
  return await service({
    method: "post",
    url: "/api/web/data/add",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};
interface deleteFileListCheck {
  id?: string;
  appCode?: string;
  fileId?: string;
}
const deleteFileList = async (param: deleteFileListCheck) => {
  return await service({
    method: "post",
    url: "/api/web/data/text/delete",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};

interface paintForm {
  prompt?: string;
  mode?: string;
  auto_translate?: boolean;
}
const paintMsg = async (param: paintForm) => {
  return await service({
    method: "post",
    url: "/v1/img/web/midjourney/generate",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};

interface imgDetailCheck {
  type?: string;
  img_id?: string;
  index?: boolean;
}

const imgDetail = async (param: imgDetailCheck) => {
  return await service({
    method: "post",
    url: "/v1/img/web/midjourney/operate",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};
interface getMsgCheck {
  task_id?: string;
}
const getImg = async (param: getMsgCheck) => {
  return await service({
    method: "get",
    url: `/v1/img/web/midjourney/tasks/${param.task_id}`,
  });
};

const sendRhyPostInfo =  async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/voice/stream",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: 'blob',
    data: param,
  });
}

const sendRhygetInfo =  async () => {
  return await service({
    method: "get",
    url: "/api/web/tts/stream?text=告诉我你的想法",
    responseType: 'blob',
  });
}

interface appCheck {
  appCode?: string;
}
const getModel = async (param: appCheck) => {
  return await service({
    method: "get",
    url: `/api/web/app/models?appCode=${param.appCode}`,
  });
};

interface loginCheck {
  username: string;
  password: string;
}

const passwordLogin = async (param: loginCheck) => {
  return await service({
    method: "post",
    url: "/api/login",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    data: param,
  });
};
interface verificationCodeCheck {
  phoneNumber: string;
}
const getVerificationCode = async (param: verificationCodeCheck) => {
  return await service({
    method: "get",
    url: '/api/get/verification/code',
    params: param,
  });
};

// 注册
interface registerCheck {
  accountNo: string;
  password: string;
  verificationCode: string;
  shareUserId?: string
}

const registerAccount = async (param: registerCheck) => {
  return await service({
    method: "post",
    url: "/api/register",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};

interface updatePasswordCheck {
  accountNo?: string,
  newPassword?: string,
  oldPassword?: string,
  verificationCode?: string,
}
const updatePassword = async (param?: updatePasswordCheck) => {
  return service({
    method: "post",
    url: "/api/chat/web/app/user/update/password",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const forgetPwd = async (param?: updatePasswordCheck) => {
  return service({
    method: "post",
    url: "/api/forget/password",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const signDay = async () => {
  return await service({
    method: "get",
    url: '/api/chat/web/app/user/sign/in',
  });
};

const weChatPay = async (param: any) => {
  return service({
    method: "post",
    url: "/api/chat/wx/createOrder",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface bindPubilc {
  accountNo: string
}
const bindPubilcPhone = async (param: bindPubilc) => {
  return await service({
    method: "get",
    url: '/api/wx/public/login/getWeChatPublicAccountImageBase64ToBindAccount',
    params: param,
  });
};


const queryBase = async (param?: any) => {
  return service({
    method: "post",
    url: "/api/web/knowledge/base/create",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const benefitsList = async (param: GetUserOrderCheck) => {
  return await service({
    method: "get",
    url: "/api/chat/web/benefits/list",
    params: param,
  });
};

const benefitsAccount = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/benefits/account",
  });
};

const invitationCode = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/user/get/invitation/code",
  });
};

const getBaseList = async () => {
  return await service({
    method: "get",
    url: "/api/web/knowledge/base/list",
  });
};

interface createBaseCheck {
  name: string,
  desc: string
}

const createBaseApi = async (param: createBaseCheck) => {
  return await service({
    method: "post",
    url: "/api/web/knowledge/base/create",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface parseBaseCheck {
  chunkSize: number,
  url: string
}

const parseBase = async (param: parseBaseCheck) => {
  return await service({
    method: "post",
    url: "/api/web/knowledge/base/file/parse",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface baseListCheck {
  code: string
  page_no: number
  page_size: number
}

const baseList = async (param: baseListCheck) => {
  return await service({
    method: "get",
    url: '/api/web/knowledge/base/file/list',
    params: param,
  });
};

interface filesCheck {
  url: string
  originFileName: string
}
interface embeddingCheck {
  code: string
  chunkSize: number
  files: filesCheck[]
  onlySearchQuestion?: any
}

const embeddingExport = async (param: embeddingCheck) => {
  return await service({
    method: "post",
    url: "/api/web/knowledge/base/file/embedding",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface baseSearchCheck {
  code: string
  query: string
  k: number
}

const baseSearch = async (param: baseSearchCheck) => {
  return await service({
    method: "post",
    url: "/api/web/knowledge/base/search",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface cashoutParam {
  amount: number
  type: string
}

const cashOut = async (param: cashoutParam) => {
  return await service({
    method: "post",
    url: "/api/chat/web/benefits/cashout",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface baseRelationCheck {
  appCode: string
  dataCode: string
}

const bindBase = async (param: baseRelationCheck) => {
  return await service({
    method: "post",
    url: "/api/chat/web/app/knowledge/base/relation/add",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const deleteBaseApi = async (param: baseRelationCheck) => {
  return await service({
    method: "post",
    url: "/api/chat/web/app/knowledge/base/relation/delete",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getAllModel = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/all/ai/model",
  })
};

const baseDetial = async (param: RoleDetailCheck) => {
  return await service({
    method: "get",
    url: '/api/web/knowledge/base/detail',
    params: param,
  });
};

const baseDetele = async (param: RoleDetailCheck) => {
  return await service({
    method: "post",
    url: '/api/web/knowledge/base/delete',
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
interface updataBaseCheck {
  code: string
  iconUrl: string
  name: string
  desc: string
}
const updataBase = async (param: updataBaseCheck) => {
  return await service({
    method: "post",
    url: '/api/web/knowledge/base/update',
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const roleThumb = async (param: appCheck) => {
  return await service({
    method: "get",
    url: '/api/chat/web/app/role/thumb',
    params: param,
  });
};

const roleCollection = async (param: appCheck) => {
  return await service({
    method: "get",
    url: '/api/chat/web/app/role/collection',
    params: param,
  });
};

// 插件接口
const allPluginsList = async () => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/plugins/template/list',
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const pluginsList = async (param: appCodeCheck) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/plugins/list',
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    data: param,
  });
};

interface addPluginsCheck {
  appCode?: string
	args?: string
	config?: string
	enabled?: string
	id?: number
	pluginId?: number
}
const addPlugins = async (param: addPluginsCheck) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/plugins/insert',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

interface deletePulginCheck {
  id: string
}

const deletePulgins = async (param: deletePulginCheck) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/plugins/delete',
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    data: param,
  });
};

const pluginSwitch = async (param: addPluginsCheck) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/plugins/switch',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

// userKey

const queryUserKey = async () => {
  return await service({
    method: "get",
    url: '/api/chat/web/app/user/query/user/key',

  });
};

interface createUserKeyCheck{
  id?: number
	name?: string   
	subType?: string
	type?: string
	userId?: number
	userKey?: string
}
const createUserKey = async (param: createUserKeyCheck) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/user/create/user/key',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

const editUserKey = async (param: createUserKeyCheck) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/user/update/user/key',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

interface deleteUserKeyCheck {
  id?: string
}
const deleteUserKey = async (param: deleteUserKeyCheck) => {
  return await service({
    method: "delete",
    url: "/api/chat/web/app/user/delete/user/key",
    params: param,
  });
};
interface autoCreateCheck {
  name: string
  description: string
}
const autoCreateImg = async (param: autoCreateCheck) => {
  return await service({
    method: "post",
    url: '/api/web/app/avatar/autoCreate',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};
const autoCreate = async (param: autoCreateCheck) => {
  return await service({
    method: "post",
    url: '/api/web/app/autoCreate',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};
interface createFileCheck {
  fileName: string
  type: string
  dataCode: string
}
const createFile = async (param: createFileCheck) => {
  return await service({
    method: "post",
    url: '/api/web/file/create',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

const exportFiles = async (param: deleteUserKeyCheck) => {
  return await service({
    method: "post",
    url: '/api/web/file/export',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};
export {
  roleAdd,
  myRole,
  roleDetail,
  apiKey,
  apiKeyList,
  changeRoleSwitch,
  userUpdate,
  getProduct,
  rechargeOrder,
  getBalance,
  getUserOrder,
  userEditUpdate,
  getRecordDetail,
  getInvokeRecord,
  roleHeighDetail,
  deleteFile,
  addFile,
  deleteRole,
  applyTokenByCode,
  uploadLinkFile,
  deleteContext,
  deleteAppKey,
  queryfileList,
  editfileList,
  addFileList,
  deleteFileList,
  paintMsg,
  getImg,
  imgDetail,
  getModel,
  passwordLogin,
  getVerificationCode,
  registerAccount,
  updatePassword,
  signDay,
  weChatPay,
  bindPubilcPhone,
  queryBase,
  benefitsList,
  benefitsAccount,
  invitationCode,
  getBaseList,
  createBaseApi,
  parseBase,
  baseList,
  embeddingExport,
  baseSearch,
  cashOut,
  bindBase,
  deleteBaseApi,
  getAllModel,
  baseDetial,
  updataBase,
  baseDetele,
  roleThumb,
  roleCollection,
  allPluginsList,
  pluginsList,
  addPlugins,
  deletePulgins,
  pluginSwitch,
  queryUserKey,
  createUserKey,
  editUserKey,
  deleteUserKey,
  sendRhyPostInfo,
  sendRhygetInfo,
  forgetPwd,
  autoCreate,
  autoCreateImg,
  createFile,
  exportFiles,
  getInvokeRecordEnum
};
