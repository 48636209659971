<template>
  <router-view />
</template>

<style lang="less">
#app {
  font-family: ui-sans-serif, system-ui, -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1f1f1f;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.must {
  position: relative;
}

.must {
  .el-form-item__label::before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
    position: absolute;
    top: 0;
    left: -14px;
  }
}
</style>
