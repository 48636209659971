export const defaultUrl = "";
export const appUrl = "https://link-ai.tech"; 



// 测试环境
// export const appId = "wx082af5a4c3d4a43c" // appId
// export const versionNum = "0.3.0"; // 版本号


// 生产环境
export const appId = "wx9bdd8623a1d433c1";
export const versionNum = "1.5.0"; // 版本号
