import {
  createRouter,
  // createWebHashHistory,
  RouteRecordRaw,
  createWebHistory,
} from "vue-router";

// import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
      path: "/",
    name: "portal",
    redirect: "/portal",
    component: () =>
        import(/* webpackChunkName: "about" */ "@/views/portal/pLayout.vue"),
    children: [
      {
        path: "/portal",
        name: "portal",
        component: () => import("@/views/portal/components/portalPage.vue"),
      },
    ],
  },
  {
    path: "/home",
    name: "home",
    redirect: "/home",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/HomeView.vue"),
    children: [
      {
        path: "/home",
        name: "HomeAll",
        component: () => import("@/views/HomeAll.vue"),
      },
      {
        path: "/app/:code",
        name: "app",
        component: () => import("@/views/ChatView.vue"),
      },
      {
        path: "/paint",
        name: "paintPic",
        component: () => import("@/views/PaintPic.vue"),
      },
    ],
  },
  {
    path: "/console",
    name: "console",
    redirect: "/console/factory",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/console/ConsoleView.vue"),
    children: [
      {
        path: "/console/factory",
        name: "myApp",
        component: () => import("@/views/console/MyApp.vue"),
      },
      {
        path: "/console/interface",
        name: "openInterface",
        component: () => import("@/views/console/OpenInterface.vue"),
      },
      {
        path: "/console/dataAnalysis",
        name: "dataAnalysis",
        component: () => import("@/views/console/DataAnalysis.vue"),
      },
      {
        path: "/console/limitManage",
        name: "limitManage",
        component: () => import("@/views/console/limitManage.vue"),
      },
      {
        path: "/console/createApp",
        name: "createApp",
        component: () => import("@/views/console/CreateApp.vue"),
      },
      {
        path: "/console/embeddingApp",
        name: "EmbeddingApp",
        component: () =>
          import("@/views/console/EmbeddingApp/EmbeddingApp.vue"),
      },
      {
        path: "/console/promptApp",
        name: "promptApp",
        component: () => import("@/views/console/PromptApp.vue"),
      },
      {
        path: "/console/account",
        name: "myCenter",
        component: () => import("@/views/console/myCenter/MyCenter.vue"),
      },
      {
        path: "/console/fileDetail",
        name: "fileDetail",
        component: () => import("@/views/console/FileDetail.vue"),
      },
      {
        path: "/console/pluginAll",
        name: "pluginAll",
        component: () => import("@/views/console/PluginAll.vue"),
      },
      {
        path: "/console/knowledgeBase",
        name: "knowledgeBase",
        component: () => import("@/views/console/KnowledgeBase/KnowledgeBase.vue"),
      },
      {
        path: "/console/knowledgeBase/knowledgeManage",
        name: "KnowledgeManage",
        component: () => import("@/views/console/KnowledgeBase/KnowledgeManage.vue"),
        redirect: "/console/knowledgeBase/baseAll",
        children: [{
          path: "/console/knowledgeBase/baseAll",
          name: "baseAll",
          component: () => import("@/views/console/KnowledgeBase/BaseAll.vue"),
        },
        {
          path: "/console/knowledgeBase/baseFile",
          name: "baseFile",
          component: () => import("@/views/console/KnowledgeBase/BaseFile.vue"),
        },
        {
          path: "/console/knowledgeBase/importFile",
          name: "importFile",
          component: () => import("@/views/console/KnowledgeBase/ImportFile.vue"),
        },
        {
          path: "/console/knowledgeBase/retrievalFile",
          name: "retrievalFile",
          component: () => import("@/views/console/KnowledgeBase/RetrievalFile.vue"),
        },
        {
          path: "/console/knowledgeBase/baseSetting",
          name: "baseSetting",
          component: () => import("@/views/console/KnowledgeBase/BaseSetting.vue"),
        }]
      },
    ],
  },
  {
    path: '/pluginKey',
    name: "pluginKey",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/pluginKey.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/ChatView.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    //访问主页的时候 重定向到index页面
    redirect: "/404",
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/Not404.vue"),
  },
];

const router = createRouter({
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 }
  },
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  next();
});
export default router;
