import axios, { type AxiosResponse } from "axios";
import { defaultUrl } from "@/utils/url";
import { ElMessage } from "element-plus";

import { createStore } from "@/vuex/store";
// import { fa } from "element-plus/es/locale";
const store = createStore();
let token = "";

const service = axios.create({
  baseURL: defaultUrl,
  timeout: 10000 * 6,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
});
let verifyLogin = false;
let showWarning = false
const noTokenUrl: string[] = [
  "/api/chat/web/role/list",
  "/api/chat/web/role/group/list",
  "/api/wx/public/login/getWeChatPublicAccountImageBase64",
  "/api/wx/public/login/check/state",
  "/api/wx/OAuth/login/getAuthUrl",
  "/api/wx/OAuth/login/apply/token",
  "/api/get/verification/code",
  "/api/login",
  "/api/register",
  "/api/wx/public/login/check/bind/state",
  "/api/chat/web/app/user/getWeChatPublicAccountImageBase64ToBindAccount",
  "/api/forget/password",
  "/api/chat/web/app/all/ai/model",
  "/api/chat/web/app/plugins/template/list"
];
interface failCheck {
  [key: number]: string;
}
const statusMap: failCheck = {
  401: "身份过期，请重新登录",
  406: '您的余额不足，请点击右上角头像，前往 "我的账户" 查看',
  410: "图片生成失败，请检查提示词参数或内容",
  461: "作图任务未找到，请重试或联系客服处理",
  503: "当前服务不可用，请重试或联系客服处理",
  511: " 请耐心等待当前任务完成",
  561: "图片生成遇到了一点小问题，请重试一下吧",
  599: "未知异常，请重试或联系客服处理",
};

service.interceptors.request.use(
  (config) => {
 
    let indexUrl = -1;

    if (config.url) {
      indexUrl = noTokenUrl.indexOf(config.url);
    }
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token") || "";
    }

    if (!token && indexUrl === -1) {
      ElMessage({
        type: "warning",
        message: "请先登录",
      });
      // return Promise.reject(config)
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

service.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    if (response.data.code === 401) {
      store.action.clearToken();
      store.action.updateUser({});
      ElMessage({
        type: "warning",
        message: "请重新登录",
      });
    }
    if (
      !response.data.success &&
      response.data.code !== 701 &&
      response.config.url !== "/api/chat/web/app/add" &&
      response.config.url !== "/api/web/embedding" &&
      response.config.url !== "/api/chat/web/app/user/sign/in" &&
      response.config.url !== "/api/web/voice/stream"
    ) {
      ElMessage({
        type: "error",
        message: response.data.message,
      });
    }
    return response.data;
    // throw new Error(response.status.toString());
  },
  (error) => {
    if (
      error.message === "timeout of 60000ms exceeded" &&
      error.config.url.indexOf("/v1/img/web/midjourney/tasks/") > -1
    ) {
      return;
    }
    const isPaint = error.config.url.indexOf("/v1/img/web/midjourney");
    const status = error.response.status;
    if (error.response && status === 401) {
      store.action.clearToken();
      store.action.updateUser({});
      verifyLogin = true;
      if (verifyLogin) {
        ElMessage({
          type: "warning",
          message: "请重新登录",
        });
        setTimeout(() => {
          verifyLogin = false;
        }, 3000);
      }
    } else if (isPaint > -1) {
      ElMessage({
        type: "warning",
        message: statusMap[status],
      });
    } else {
      if(!showWarning){
        ElMessage({
          type: "warning",
          dangerouslyUseHTMLString: true,
          message: "网络遇到一些问题，请点击刷新，或联系客服处理~<button id='mesbtn' style='height: 32px; line-height: 32px; margin-left: 15px; padding: 0 15px; border:1px solid #dcdfe6; background-color: #fff; color: #606266; cursor: pointer'>刷新</button>",
        });
        showWarning = true
        const b = document.getElementById('mesbtn')
        if (b) {
             b.addEventListener('click', () => {
             window.location.reload()
          })
          }
        setTimeout(() => {
          showWarning = false
        },3000)
      }
    }
    return Promise.reject(error);
  }
);

export default service;
