import { reactive } from "vue";

interface NowSelect {
  id?: number | null;
  name?: string;
  code?: string;
  thumb?: boolean;
  thumbCount?: number;
  collect?: boolean;
  collectCount?: number
}
export interface checkState {
  user: any;
  chatData: any;
  userList: any;
  isPhone: boolean;
  nowSelect: NowSelect;
  token: string;
  balance?: number | null;
  weixin?: boolean
  searchTime?: number
  searchTimeInput?: string
  scoreTime?: number
  allModel?: any
  allPluginUser?: any
}
export const State: checkState = {
  user: {},
  chatData: [],
  userList: [],
  isPhone: false,
  nowSelect: {
    id: null,
    name: "",
    code: "",
    thumb: false,
    thumbCount: 0,
    collect: false,
    collectCount: 0
  },
  token: "",
  balance: null,
  weixin: false,
  searchTime: 0,
  searchTimeInput: '',
  scoreTime: 0,
  allModel: [],
  allPluginUser: []
};
export function createState() {
  return reactive(State);
}
