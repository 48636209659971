import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import store from './store'
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import dayjs from "dayjs";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import './less/variable.less'
import { createStore } from "@/vuex/store";
import {  applyTokenByCode, invitationCode,getAllModel,allPluginsList } from "@/api/api";
// import { isToken } from "@/utils/utils";
// import { extractUrlParams } from "@/utils/utils";
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css
import { GlobalCmComponent } from "codemirror-editor-vue3";
import 'codemirror/lib/codemirror.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
const store = createStore();
if (localStorage.getItem("userList")) {
  store.action.repeatUserList(
    JSON.parse(localStorage.getItem("userList") || "")
  );
}

if (localStorage.getItem("allPluginUser")) {
  store.action.repeatAllPlugin(
    JSON.parse(localStorage.getItem("allPluginUser") || "")
  );
}
// setTimeout(() => {
//   store.action.clearToken()
// }, 1000)
if (
  localStorage.getItem("nowSelect") &&
  localStorage.getItem("nowSelect") !== "undefined"
) {
  store.action.repeatNowSelect(
    JSON.parse(localStorage.getItem("nowSelect") || "")
  );
}

if (localStorage.getItem("chatData")) {
  store.action.repeatChatData(
    JSON.parse(localStorage.getItem("chatData") || "")
  );
}

getAllModel().then((ret) => {
  store.action.updataAllModel(ret.data)
});
allPluginsList().then((res) => {
  store.action.updataAllPlugin(res.data)
})
if (localStorage.getItem("token")) {
  store.action.setToken(localStorage.getItem("token") || "");
  if (!localStorage.getItem('shareId')) {
    invitationCode().then((red: any) => {
      if (red.success) {
        localStorage.setItem('shareId', red.data);
      }
    });
  }
}

if (localStorage.getItem("user")) {
  store.action.updateUser(JSON.parse(localStorage.getItem("user") || ""));
}


function isMobile() {
  const regex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  const isMobile = regex.test(navigator.userAgent);
  const isWxin = navigator.userAgent.toLowerCase();
  const isWeixin = isWxin.indexOf("micromessenger") != -1;
  if (isWeixin) {
    store.action.upDataIsWexin(true);
  }
  if (isMobile || isWeixin) {
    store.action.checkPhone(true);
  }
}

isMobile();

import "element-plus/dist/index.css";
// import { setupI18n } from './locales'
const app = createApp(App);
app.config.globalProperties.$dayjs = dayjs;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(vue3videoPlay)
app.use(router);
app.use(ElementPlus, {
  locale: zhCn,
});
app.use(GlobalCmComponent);
app.use(ElementPlus).mount("#app");
app.use(Viewer, {
  Options: {
    'inline': true,
    'button': true, //右上角按钮
    "navbar": false, //底部缩略图
    "title": true, //当前图片标题
    "toolbar": false, //底部工具栏
    "tooltip": true, //显示缩放百分比
    "movable": true, //是否可以移动
    "zoomable": true, //是否可以缩放
    "rotatable": false, //是否可旋转
    "scalable": false, //是否可翻转
    "transition": true, //使用 CSS3 过度
    "fullscreen": true, //播放时是否全屏
    "keyboard": true, //是否支持键盘
    "url": "data-source",
  }
});
const localUrl = window.location.href;
const hrefParams: Record<string, string> = {};
const queryParamsIndex = localUrl.indexOf("?");

if (queryParamsIndex !== -1) {
  const params = localUrl.substring(queryParamsIndex + 1);
  const arr = params.split("&");
  const arrs: string[] = [];

  for (let j = 0; j < arr.length; j++) {
    arrs.push(...arr[j].split("="));
  }

  for (let i = 0; i < arrs.length; i += 2) {
    hrefParams[arrs[i]] = arrs[i + 1];
  }
}
if (hrefParams.share) {
  localStorage.setItem("shareCode", hrefParams.share);
}
if (hrefParams.voice) {
  localStorage.setItem("showVoice", hrefParams.voice);
}else{
  localStorage.setItem("showVoice", String(false));
}
if (hrefParams.code && !localStorage.getItem("token")) {
  let shareCode = "";
  let msg = {};
  if (localStorage.getItem("shareCode")) {
    shareCode = localStorage.getItem("shareCode") || "";
    msg = {
      code: hrefParams.code,
      share: shareCode,
    };
  } else {
    msg = {
      code: hrefParams.code,
    };
  }
  applyTokenByCode(msg).then((res: any) => {
    if (res.success) {
      store.action.setToken(res.data.token);
      store.action.updateUser(res.data.user);
      invitationCode().then((red: any) => {
        if (red.success) {
          localStorage.setItem('shareId', red.data);
        }
      });
      if (localStorage.getItem("shareCode")) {
        localStorage.removeItem("shareCode");
      }
      router.go(0);
    }
  });
}

// const regex = /\/app\/([^?]+)/;
// const match = localUrl.match(regex);
// if (match) {
//   const appCode = match[1];
//   if (isToken()) {
//     roleDetail({
//       code: appCode,
//     }).then((res: any) => {
//       if (res.success) {
//         const item = res.data;
//         store.action.updateUserList(item);
//         const data = {
//           person: 1,
//           text: item.introduction,
//           date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
//         };
//         const isFirst = true;
//         store.action.updateChatData(item.id, data, isFirst);
//         store.action.repeatNowSelect(item);
//         router.push({
//           name: "app",
//           params: { code: appCode },
//         });
//       } else {
//         router.push({
//           name: "home",
//         });
//       }
//     }).catch(() => {
//       router.replace({
//         name: "home",
//       });
//     })
//   }
// }

// const debounce = (fn: any, delay: any) => {
//   let timer: any = null;
//   return function (this: any) {
//     const context = this;
//     const args = arguments;
//     clearTimeout(timer);
//     timer = setTimeout(function () {
//       fn.apply(context, args);
//     }, delay);
//   };
// }

// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
//   constructor(callback: any) {
//     callback = debounce(callback, 16);
//     super(callback);
//   }
// }

